import Image from "next/dist/client/image";
import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { formHelpers } from "../../../../lib/formHelpers";
import { IDealerListData } from "../../../../lib/queries/DealerQuery";
import { TestDriveCarInfo } from "../../../../lib/queries/TestDriveCarInfoQuery";
import { DataLayerPush } from "../../../../lib/tracking/DataLayer";
import Footer from "../../../Footer/Footer";
import FooterModal from "../../../FooterModal";
import TopBanner from "../../TopBanner";
import FormComponent from "./FormComponent";
import FacebookPush from "../../../../lib/tracking/FacebookPush";

interface ITestDriveForm {
    showModal: boolean;
    setShowModal: React.SetStateAction<boolean>;
    data: IDealerListData;
    carData?: TestDriveCarInfo;
}

const TestDriveForm = ({
    showModal,
    setShowModal,
    data,
    carData,
}: ITestDriveForm) => {
    const [postNumber, setPostNumber] = useState<string>();
    const fullDealersList = data;
    const [filteredDealers, setFilteredDealers] = useState() as any[];
    const [selectedDealerForm, setSelectedDealerForm] = useState(null);
    const [dealerPreviouslySelected, setDealerPreviouslySelected] =
        useState(false);

    useEffect(() => {
        if (carData && carData.name) {
            DataLayerPush(window, {
                car: carData.name,
                event: "formLoadTestDrive",
            });
            FacebookPush("track", "formLoadTestDrive");
        }
    }, [carData]);

    useEffect(() => {
        if (postNumber) {
            const filteredDealers = formHelpers.getPostalNumber(
                postNumber,
                fullDealersList
            );
            setFilteredDealers(filteredDealers);
            setDealerPreviouslySelected(true);
        }
    }, [postNumber, fullDealersList]);

    return (
        <>
            <TopBanner
                title={`Prøvekjør`}
                showModal={showModal}
                setShowModal={setShowModal}
            />
            <ContentWrapper>
                {carData.testDriveImage && (
                    <Background>
                        <Image
                            unoptimized={true}
                            src={carData.testDriveImage}
                            layout="fill"
                            objectFit="cover"
                            quality={90}
                            objectPosition="50% 50%"
                            alt={carData.name}
                            placeholder="blur"
                            blurDataURL="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mP8Xw8AAoMBgDTD2qgAAAAASUVORK5CYII="
                        />
                    </Background>
                )}
                <FormComponent
                    carData={carData}
                    postNumber={postNumber}
                    setPostNumber={setPostNumber}
                    filteredDealers={filteredDealers}
                    selectedDealerForm={selectedDealerForm}
                    setSelectedDealerForm={setSelectedDealerForm}
                    dealerPreviouslySelected={dealerPreviouslySelected}
                    setDealerPreviouslySelected={setDealerPreviouslySelected}
                />
            </ContentWrapper>
            <FooterModal setShowModal={setShowModal} />
            <Footer />
        </>
    );
};

export default TestDriveForm;

const ContentWrapper = styled.div`
    display: flex;
    flex-direction: column;
    position: relative;
`;

const Background = styled.div`
    position: absolute;
    min-height: 100%;
    width: 100%;
    overflow: hidden;
    z-index: -1;
`;
